import api from '../../utils/api';

const state = {
    fileManager: []
};

const getters = {
    getFileManagerData: () => state.fileManager
};

const actions = {
    async fetchFileManagerData({commit}){
        try{
            let {data: { data: files} } = await api.get('files/folders');
            commit('setFileManagerData',files);
        } catch(error){
            console.error(error.message);
            return false;
        }
    },
    async moveFile({commit, state}, {fileId, currentFolderId, newFolderId}){

        let currentState = [...state.fileManager];
        let savedState = JSON.stringify(currentState); // Had to save it as string so it didn't get referenced
        try {
            // 
            ////// Optimistic UI Update
            //
            let currentFolderIndex = currentState.findIndex(f=>f._id==currentFolderId);
            if(currentFolderIndex === -1) return Promise.reject('Folder not found');
            let currentFileIndex = currentState[currentFolderIndex].files.findIndex(f=>f.id==fileId);
            if(currentFileIndex === -1) return Promise.reject('File not found');

            // Prep file for insertion into new folder
            let fileToMove = currentState[currentFolderIndex].files[currentFileIndex];
            fileToMove.folder = newFolderId;

            // Remove file from current folder;
            let updatedState = [...currentState];
            updatedState[currentFolderIndex].files.splice(currentFileIndex,1);

            // Add file to new folder
            let newFolderIndex = updatedState.findIndex(f=>f._id==newFolderId);
            if(newFolderIndex !== -1){
                updatedState[newFolderIndex].files = [...(updatedState[newFolderIndex].files || []), fileToMove];
            } else {
                updatedState.push({
                    _id: newFolderId,
                    files: [fileToMove]
                });
            }

            commit('setFileManagerData',updatedState);

            //API Call
            await api.put(`files/${fileId}`,{
                folder: newFolderId
            });

            return Promise.resolve();

        } catch(err){
            // Revert optimistic update on error
            commit('setFileManagerData',JSON.parse(savedState));
            return Promise.reject(err);
        }
    },
    async renameFolder({commit, state}, {currentFolderId, newFolderId}){
        let currentState = [...state.fileManager];
        let savedState = JSON.stringify(currentState); // Had to save it as string so it didn't get referenced
        try {
            // 
            ////// Optimistic UI Update
            //
            let updatedState = [...currentState];
            for (let folder of updatedState){
                if(folder._id.indexOf(currentFolderId) === 0){
                    folder._id = folder._id.replace(currentFolderId,newFolderId);
                }
            }

            commit('setFileManagerData',updatedState);

            // API Call
            await api.put('files/folders',{
                new_name: newFolderId,
                old_name: currentFolderId
            });
            
            return Promise.resolve();

        } catch(err){
            // Revert optimistic update on error
            commit('setFileManagerData',JSON.parse(savedState));
            return Promise.reject(err);
        }
    },
    async renameFile({commit, state}, {fileId, newName, folderId}){
        let currentState = [...state.fileManager];
        let savedState = JSON.stringify(currentState); // Had to save it as string so it didn't get referenced
        try {
            // 
            ////// Optimistic UI Update
            //
            let updatedState = [...currentState];
            for (let folder of updatedState){
                if(folder._id.indexOf(folderId) === 0){
                    folder.files.map(f=>{
                        if(f.id == fileId) f.name = newName;
                        return f;
                    });
                }
            }

            commit('setFileManagerData',updatedState);

            // API Call
            await api.put(`files/${fileId}`,{
                name: newName
            });
            
            return Promise.resolve();

        } catch(err){
            // Revert optimistic update on error
            commit('setFileManagerData',JSON.parse(savedState));
            return Promise.reject(err);
        }
    },
    async removeFile({commit, state}, {fileId, folderId}){
        let currentState = [...state.fileManager];
        let savedState = JSON.stringify(currentState); // Had to save it as string so it didn't get referenced
        try {
            // 
            ////// Optimistic UI Update
            //
            let updatedState = [...currentState];
            for (let folder of updatedState){
                if(folder._id.indexOf(folderId) === 0){
                    folder.files = folder.files.filter(f=>{
                        if(f.id == fileId) return false;
                        return true;
                    });
                }
            }

            commit('setFileManagerData',updatedState);

            // API Call
            await api.post('files/delete',{
                files: [fileId]
            });
            
            return Promise.resolve();

        } catch(err){
            // Revert optimistic update on error
            commit('setFileManagerData',JSON.parse(savedState));
            return Promise.reject(err);
        }
    },
    async searchFiles({commit}, query){
        try {
            let {data: { data: files} } = await api.get('files/search', {
                params: {
                    grouped: true,
                    q: query
                }
            });
            commit('setFileManagerData',files);
            
            return Promise.resolve();

        } catch(err){
            return Promise.reject(err);
        }
    }
};

const mutations = {
    setFileManagerData: (state, data)=>state.fileManager = data
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};