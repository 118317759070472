<template>
  <div id="app">
    <Nav />
    <router-view/>
    <Uploader />
    <Footer />
  </div>
</template>

<script>
import Nav from './components/Nav.vue';
import Footer from './components/Footer.vue';
import Uploader from './components/Uploader';
import './assets/Tooltip/main.css';

export default {
  name: 'App',
  components: {
    Nav,
    Footer,
    Uploader
  }
};
</script>

<style>
* {
  box-sizing: border-box;
  margin:0;
  padding:0
}
#app {
  font-family: ProximaNova, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #102445;
  min-height:100vh;
  display:grid;
  grid-gap: 20px;
  grid-template-rows: 55px 1fr 62px;
}
#page-content {
  max-width:1600px;
  padding:20px;
  margin:0 auto;
  position: relative;
  z-index: 0;
  padding-bottom: 0;
}
.backdrop {
    background-color: #FFF;
    border-radius: 63px;
    max-height: calc(100vh + -200px);
    min-height: 600px;
    height: 0px;
    max-width: 1250px;
    margin: 0 auto;
    position: relative;
    padding: 50px 100px;
}
.backdrop h1 {
    font-size: 54px;
    position: relative;
    font-weight: 700;
    text-align: left;
}
.separator{
  margin-top:30px;
  margin-bottom:30px;
  border-bottom:1px solid #CCCCDB
}
.tooltip.popover.vue-popover-theme{
  z-index:9;
  background-color: #F1F2F6;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #1d2f4d29;
  max-width: 360px;
}
p.v-toast-text {
    font-family: ProximaNova;
}
.dg-content {
  font-family: ProximaNova;
}

.dg-btn--cancel {
    background-color: #ccc !important;
}
.dg-btn--ok {
    background-color: #102445 !important;
    color: #FFF !important;
    border-color: #102445 !important;
}
.vue-simple-context-menu{
  position: fixed !important;
}
.context-delete:hover {
  background-color:red !important;
  color:#FFF;
}
.popover.delete-file-confirm {
    font-family: ProximaNova !important;
    box-shadow: 0px 0px 20px #707070d4;
}
.popover.delete-file-confirm .delete-form button {
    width: 80px;
    background-color: #102445;
    border-radius: 50px;
    text-transform: uppercase;
    color: #FFF;
    border: unset;
    font-size: 15px;
    height: 30px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 10px;}

.popover.delete-file-confirm .delete-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    font-size: 18px;
}
.popover.delete-file-confirm {
    top: 52px !important;
}
</style>
