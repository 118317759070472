<template>
  <div class="main-section" id="home">
    <Background v-bind:backgroundSrc="backgroundSrc"/>
    <div id="page-content">
      <div class="backdrop">
        <FileManager />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Background from '../components/Background/Background';
import FileManager from '../components/FileManager';

export default {
  name: 'Home',
  components: {
    Background,
    FileManager
  },
  data(){
      return {
          backgroundSrc: 'Home.svg'
      };
  },
};
</script>

<style scoped>
#app {
  background-color:#fefced;
}
#home{
  min-height: 615px;
}

#home #page-content {
  margin: 0;
  padding: 0;
  max-width: unset;
}

.backdrop {
  max-width: unset;
  max-height: unset;
  background-color: rgb(16 36 69 / 53%);
  border-radius: 13px;
  display: block;
  height: calc(100vh - 165px);
  width: calc(100vw - 30px);
  margin: 0 auto;
  color: #fff;
  position: relative;
  min-height: 607px;
  padding: 5px;
}

.backdrop h1 {
    text-align: center;
    font-size: 54px;
    position: relative;
    top: 4%;
    font-weight: 700;
}

.backdrop h4 {
    font-size: 18px;
    font-weight: 100;
    position: relative;
    top: 5%;
}

img#home-1 {
    position: absolute;
    left: -20px;
    top: -1%;
}

img#home-2 {
    position: absolute;
    left: 70px;
    top: 32%;
}

img#home-3 {
    position: absolute;
    right: -20px;
    top: 6%;
}

img#home-4 {
    position: absolute;
    right: 10px;
    top: 42%;
}
</style>