<template>
  <section class="progress-container">
      <section class="background"></section>
      <svg
        class="progress"
        :height="radius * 2"
        :width="radius * 2"
    >
        <circle
        stroke="#2eb382bd"
        fill="transparent"
        stroke-linecap="round"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="{ strokeDashoffset }"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
        />
    </svg>
    <h1 class="progress-text">{{(progress%10 == 0) ? progress : progress.toFixed(1)}}%</h1>
    <p class="eta" :class="{miniature}" v-if="ETA && progress<100">ETA: {{ETA}}</p>
  </section>
  
</template>

<script>
export default {
    props: {
        radius: Number,
        progress: Number,
        stroke: Number,
        miniature: Boolean
    },
    data() {
        const normalizedRadius = this.radius - this.stroke;
        const circumference = normalizedRadius * 2 * Math.PI;

        return {
            normalizedRadius,
            circumference,
            startTime: 0,
            ETA: 0,
            ETATimeToStale: 0,
        };
    },
    computed: {
        strokeDashoffset() {
            return this.circumference - this.progress / 100 * this.circumference;
        }
    },
    mounted(){
        this.startTime = new Date().getTime();
    },
    watch: {
        progress(){
            const currentTime = new Date().getTime();

            if(currentTime < this.ETATimeToStale) return;

            let timeElapsed = Math.ceil((currentTime - this.startTime)/1000);

            let f = new Date(Math.ceil(100/this.progress*timeElapsed-timeElapsed) * 1000).toISOString().substr(11, 8).split(':').map(s=>parseInt(s));

            this.ETA = `${(f[0])?f[0]+'h ':''}${f[1]?f[1]+'m ':''}${f[2]?f[2]+'s':''}`;

            this.ETATimeToStale = currentTime+1000;
        }
    }
};
</script>

<style scoped>
section.progress-container {
    width: 102px;
    height: 102px;
    position: relative;
    margin: 30px auto 10px;
}
section.progress-container .background {
    width: 100%;
    height: 100%;
    border: 17px solid #d1d1d182;
    border-radius: 50%;
}
svg.progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
}
h1.progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
}
.eta{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 40px));
    width: max-content;
    background: #ffffff73;
    border: 1px solid #CCC;
    padding: 0 5px;
    border-radius: 10px;
}
.eta.miniature{
    font-size: 34px;
    white-space: nowrap;
    margin-top: 10px;
    position: absolute;
    top: 246px;
}
</style>