<!-- eslint-disable-next-line vue/no-v-html -->
<template>
  <div v-if="uploadState.open && ((isMiniature && uploading) || !isMiniature)" class="uploader" :class="{init: isLoading(), miniature: isMiniature, complete: uploadComplete}">
    <button id="uploader-close-button" @click="toggleOpen"><fa-icon :icon="['fas','times']" /></button>
    <section id="uploader-inner-content" v-if="!isLoading()">
      <section class="file-upload--list">
        <section 
          v-if="filesReady"
          :class="{'uploader-dragging': dropArea.dragging}"
          @dragover.prevent="dropArea.dragging = true"
          @dragleave="dropArea.dragging = false"
          @dragend="dropArea.dragging = false"
          @drop.prevent="dropHandle"
          @click.self.prevent="()=>$refs['file-input'].click()"
        >
          <span v-for="file in files" :key="file.name" class="file-upload--details">
            <FileIcon class="file-icon" :type="file.type" />
            <section class="file-details">
              <h3>{{file.name}}</h3>
              <p>{{fileSizeFormatted(file.size)}}</p>
            </section>
            <span v-if="!uploading">
              <fa-icon class="file-tooltip-icon" @click="removeFile(file)" :icon="['fas','trash-can']" />
            </span>
          </span>
          <span class="total-upload">
            Total Upload: <strong>{{totalSizeFormatted}}</strong>
          </span>
        </section>
        <section v-else 
          class="uploader-dragging"
          @dragover.prevent="dropArea.dragging = true"
          @dragleave="dropArea.dragging = false"
          @dragend="dropArea.dragging = false"
          @drop.prevent="dropHandle"
          @click="()=>$refs['file-input'].click()"
        ></section>
        <input ref="file-input" type="file" class="file-input" @change="onFileSelect" multiple>
      </section>
      <section v-if="!uploading">
        <form action="" @submit.prevent="upload" v-if="subscription && ['trialing','active'].includes(subscription.status)">
          <div id="uploader-top-section">
              <section>
                <h1 v-if="filesReady">{{filesReady}} File(s) Selected</h1>
              </section>
          </div>
          <div id="uploader-buttons">
              <button type="submit">Start Upload</button>
          </div>
        </form>
        <section class="upgrade-notice" v-else>
          <img :src="images.upgrade" alt="">
          <h3 class="upgrade-message">Upgrade to the plan that best suits your needs!</h3>
          <p class="upgrade-message">We just realized that you are not signed up to one of our packages. Put away your worries about how to get that file to your production team, or share the ten thousand pictures you took on that vacation with your friends!</p>
          <router-link to="/plans" class="upgrade">Upgrade now</router-link>
        </section>
      </section>
      <section v-else>
        <ProgressBar v-if="!uploadComplete" :radius="57.5" :progress="totalUploadPercentage" :stroke="15" :miniature="isMiniature"/>
        <h3 class="in-progress" v-if="!uploadComplete" :class="{miniature: isMiniature}">Uploading</h3>
        <h3 class="in-progress" v-if="uploadComplete">Upload Complete</h3>
        <button v-if="uploadComplete" @click="resetUploader" class="new-transfer" :class="{miniature:isMiniature}">{{isMiniature ? 'Close' : 'New Upload'}}</button>
      </section>
    </section>
    <section v-else class="loading-section">
      <Loading />
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import VTooltip from 'v-tooltip';
import { mapGetters, mapActions, mapState } from 'vuex';
import upgrade from '../assets/upgrade.svg';
import api from '../utils/api';
import '../icons';
import * as AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import Loading from '../components/Loading';
import FileIcon from '../components/FileIcon';
import ProgressBar from '../components/ProgressBar';
import ToggleButton from 'vue-js-toggle-button';
import toast from '../plugins/toast';
 
Vue.use(VTooltip);
Vue.use(ToggleButton);

export default {
  components: {
    Loading,
    ProgressBar,
    FileIcon
  },
  data(){
    return {
        images: {
          upgrade
        },
        details: {
          to: '',
          recepients: [],
          label: '',
          message: '',
          notification: false,
          private: false
        },
        errors: {
          recepients: false
        },
        dropArea:{
          dragging: false
        },
        files: [],
        uploadTasks: {},
        uploadProgress: [],
        totalUploadPercentage: 0,
        uploadedFiles: [],
        // loading: true
    };
  },
  methods: {
    ...mapGetters('user',['getSubscription','isLoading']),
    ...mapActions('user',['getToken','checkAuthState']),
    ...mapActions('files',['fetchFileManagerData']),
    ...mapActions('upload',['toggleOpen']),
    getError(type){
      return this.errors[type];
    },
    async upload(){
      
      try {
        let { data: s3 } = await api.get('s3/auth', {params:{permission:'PutObject'}});

        if(!s3.AccessKeyId){
          toast.show('Error optaining S3 credentials', {type:'error'});
          return false;
        }
        AWS.config.update({
          accessKeyId: s3.AccessKeyId,
          secretAccessKey: s3.SecretAccessKey,
          sessionToken: s3.SessionToken,
          region:process.env.VUE_APP_S3_REGION, 
          endpoint: new AWS.Endpoint(process.env.VUE_APP_S3_ENDPOINT),
          s3ForcePathStyle: true, // needed with minio?
          signatureVersion: 'v4'
        });
      } catch(err){
        console.error(err);
        toast.show(err?.response?.data?.message || err.message, {type:'error'});
        return false;
      }

      this.files.forEach((f, i)=>{
        this.uploadTasks[f.name] = new AWS.S3.ManagedUpload({
          params: {
            Bucket: process.env.VUE_APP_S3_BUCKET,
            Key: `${uuidv4()}/${f.name}`,
            Body: f
          }
        });

        this.uploadTasks[f.name].send(async (err,file) => {
          if(err){
            console.error('UPLOAD ERROR::', err);
          } else {
            this.uploadTasks[f.name].completed = true;

            let { data: newFile } = await api.post(  
                'files',
                {
                  'name':f.name,
                  'size':f.size,
                  'type':f.type,
                  's3':{
                      'location':file.Location,
                      'key':file.Key,
                      'etag':file.ETag,
                      'bucket':file.Bucket
                  }
                }
              );

            this.uploadedFiles.push(newFile.data.id);
            this.fetchFileManagerData();
            this.checkAuthState();
            console.log(`File ID: ${newFile.data.id} added to Database`);

          }
        });

        this.uploadTasks[f.name].on('httpUploadProgress', snapshot=>{
          this.setProgress(snapshot.loaded, i);
        });

      });

    },
    removeFile(file){
      this.files = this.files.filter(e=>e.name!=file.name);
    },
    dropHandle(e){
      let files = e.dataTransfer && e.dataTransfer.files || e.target && e.target.files || null;
      if(files){
        for(let f of files){
          if(!this.files.find(f2=>{
            return f.name == f2.name;
          })){
            this.files.push(f);
          }
        }
      }
      this.dropArea.dragging = false;
    },
    onFileSelect(e){
      this.dropHandle(e);
    },
    fileSizeFormatted(size){
      let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      let i = parseInt(Math.floor(Math.log(size) / Math.log(1000)));
      if (i == 0) return size + ' ' + sizes[i];
      return (size / Math.pow(1000, i)).toFixed(1) + ' ' + sizes[i];
    },
    setProgress(bytes, i){
      this.uploadProgress[i] = bytes;
      let size = 0;
      this.uploadProgress.forEach(progress=>{
        size = size + progress;
      });
      this.totalUploadPercentage = size/this.totalSizeRaw*100;
    },
    resetUploader(){
      this.details = {
        to: '',
        recepients: [],
        label: '',
        message: '',
        notification: false
      },
      this.files = [],
      this.uploadTasks = {},
      this.uploadProgress = [],
      this.totalUploadPercentage = 0;
      this.uploadedFiles = [];
    },
    log(msg){
      console.log(msg);
    }
  },
  computed: {
    ...mapState({uploadState: ['upload']}),
    subscription(){
      return this.getSubscription();
    },
    filesReady(){
      return this.files.length;
    },
    totalSizeRaw(){
      let size = 0;
      this.files.forEach(f=>{
        size += f.size;
      });
      return size;
    },
    totalSizeFormatted(){
      let size = 0;
      this.files.forEach(f=>{
        size += f.size;
      });
      let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      let i = parseInt(Math.floor(Math.log(size) / Math.log(1000)));
      if (i == 0) return size + ' ' + sizes[i];
      return (size / Math.pow(1000, i)).toFixed(1) + ' ' + sizes[i];
    },
    uploading(){
      return this.totalUploadPercentage > 0;
    },
    uploadComplete(){
      return this.files.length && this.uploadedFiles.length == this.files.length;
    },
    isMiniature(){
      return this.$route.name != 'Home';
    }
  }
};
</script>

<style scoped>
.uploader {
  width: calc(100vw - 40px);
  height: calc(100vh - 175px);
  margin: 0 auto;
  background-color: #FFF;
  border-radius: 8px;
  position: absolute;
  top: 80px;
  left: 20px;
  box-shadow: 0 5px 35px rgb(0 0 0 / 10%);
  min-height: 597px;
}
/* .uploader.init{
  height:0;
} */
#uploader-folder-icon {
  text-align: left;
}
#uploader-top-section {
    color: #222222;
    padding-top: 50px;
    padding-bottom:15px;
    position:relative;
}
#uploader-top-section section{
  display: inline-block;
  text-align: left;
  /* margin-left:20px */
}
#uploader-top-section section h3 {
  font-size:28px;
}
#uploader-top-section section h4 {
  font-size:18px;
  text-decoration: underline;
  cursor: pointer;
}
#uploader-buttons button{
  width:180px;
  background-color:#102445;
  border-radius:50px;
  text-transform: uppercase;
  color:#FFF;
  border:unset;
  font-size: 15px;
  height:50px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 20px;
}
section.upgrade-notice img {
    width: 130px;
    margin-top: 36px;
}
a.upgrade{
  display: inline-block;;
  width: 170px;
  background-color: #d47867;
  border-radius: 50px;
  text-transform: uppercase;
  color: #6E7174;
  border: unset;
  font-size: 15px;
  height: 45px;
  font-weight: 600;
  cursor: pointer;
  color: #FFF;
  text-decoration: none;
  padding:15px;
}
.upgrade-message{
  color:#000
}
section.upgrade-notice {
    padding: 20px 45px;
}
h3.upgrade-message {
    margin-bottom: 17px;
    margin-top: 13px;
    font-size: 23px;
}
p.upgrade-message {
    margin: 10px 0 20px;
}
p.error-message {
    background: unset !important;
    color: red;
    text-align: left !important;
    padding: 0 !important;
    margin-top: -18px !important;
    height: unset !important;
}
div#uploader-top-section.dragging:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #FFF;
  position: absolute;
  top: 0;
  left: 0;
}
div#uploader-top-section.dragging:after {
  content: 'Drop Files Here';
  color: #CCC;
  padding: 14px 40px;
  position: absolute;
  width: fit-content;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%);
  border: 3px dashed;
  font-size: 20px;
}
section.file-upload--list {
    font-family: ProximaNova;
    max-width: 100%;
    flex: 1;
    padding: 60px 20px 20px 20px;
}
span.file-upload--details {
    position: relative;
    display: flex;
    grid-template-columns: 1fr 50px;
    grid-template-rows: 1fr 30px;
    grid-gap: 0 20px;
    align-items: center;
}
span.file-upload--details > span {
    text-align: center;
    font-size: 23px;
    color: #c14028;
}
span.file-upload--details .file-icon {
    width: 50px;
}
span.file-upload--details .file-details {
  flex: 1;
  text-align: left;
}
.file-tooltip-icon {
    cursor: pointer;
}
span.view-file-details {
    cursor: pointer;
}
span.file-upload--details:not(:nth-last-child(1)) {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}
.total-upload{
  text-align: right;
  display: block;
}
.file-input{
  display: none;
}
.loading-section{
  width:100%;
  height:100%;
}
.new-transfer{
  width: 220px;
    background-color: #d07664;
    border-radius: 50px;
    text-transform: uppercase;
    color: #FFF;
    border: unset;
    font-size: 15px;
    height: 50px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 20px;
}
h3.in-progress {
    margin-bottom: 10px;
}
h2.in-progress {
    margin-top: 27px;
    font-size: 27px;
}

/* OUTSIDE HOME PAGE */
.uploader.miniature {
    left: unset;
    top: unset;
    right: -70px;
    bottom: -70px;
    transform: unset;
    position: fixed;
    margin-bottom: 0;
    transform: scale(.5);
    width: 320px;
    z-index: 9;
    height: 360px;
    min-height: unset;
    transition: all 0.5s ease-in-out;
}
h2.in-progress.miniature {
    display: none;
}
.complete.miniature .progress-container {
    display: none;
}
.complete.miniature {
    padding: 40px;
    height: 190px !important;
    bottom: -35px !important;
}
.complete.miniature .in-progress {
    font-size: 34px;
}
button.new-transfer.miniature {
    font-size: 25px;
}
button#uploader-close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border:  1px solid;
  background: #ff00009c;
  color: #FFF;
  cursor: pointer;
  z-index: 99;
  transition: background .2s ease-in-out, color .2s ease-in-out;
}
button#uploader-close-button:hover {
  background: #FFF;
  color: #ff00009c;
}
section#uploader-inner-content {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.uploader-dragging {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
}
.uploader-dragging:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #FFF;
  position: absolute;
  top: 0;
  left: 0;
}
.uploader-dragging:after {
  content: 'Drop Files Here';
  color: #cccccc91;
  background: #f0f8ff;
  position: absolute;
  border: 5px dashed;
  font-size: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 99;
  align-items: center;
  justify-content: center;
}
.file-upload--list > section {
  height: 100%;
}
</style>
