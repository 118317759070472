<template>
  <section id="no-found-container">
      <div>
        <img src="@/assets/gray-folder.svg" alt="Folder Icon">
        <p>No Files Found</p>
    </div>
  </section>
</template>

<script>
export default {
    name: 'NoFilesFound',
};
</script>

<style scoped>
#no-found-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;
}
#no-found-container img {
    opacity: .5;
    margin-top: 15px;
}
#no-found-container p {
    font-size: 20px;
    color: #ccc;
    margin-top: 15px;
}
</style>