import Vue from 'vue';
import App from './App.vue';
import AppError from './utils/error';
import router from './router';
import store from './store';
import firebase from '@/plugins/firebase';
import api from './utils/api';

Vue.config.productionTip = false;
Vue.config.errorHandler = async function(err){
  console.log(err);
  await AppError(err);
};

let app;
firebase.auth().onAuthStateChanged(async user => {
  if (user) {
    try{
      let token = await firebase.auth().currentUser.getIdToken(true).then(id=>id);
      let dbUser = await api.get('users/me');
      store.commit('user/setUser',{...user, app: dbUser.data});
      store.commit('user/setToken',token);
      store.commit('user/setLoading',false);
    } catch (err){
      store.commit('user/setLoading',false);
      store.commit('user/setUser',null);
      store.commit('user/setToken',null);
      document.title = 'Encountered an error | Anyfile';
      app = await AppError();
    }
  } else {
    // user module reset
    store.commit('user/setUser',null);
    store.commit('user/setLoading',true);
    store.commit('user/setToken',null);

    // transfer module reset
    store.commit('setCurrentSentPage',0);
    store.commit('setNextSentPage',0);
    store.commit('setSentTransfers',[]);
    store.commit('setCurrentReceivedPage',0);
    store.commit('setNextReceivedPage',0);
    store.commit('setReceivedTransfers',[]);
  }
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');
  }
});
