import API from '../../utils/api';
const PER_PAGE = 25;

const state = {
    data: [],
    has_more: null,
    after: null
};

const getters = {
    isEmpty: (state) => (!state.data && true || false)
};

const actions = {
    async fetchInvoices({commit}){
        if(state.has_more !== null && !state.has_more) return;
        try{
            let {data: { data: invoices, has_more} } = await API.get(  
                `billing/invoices?limit=${PER_PAGE}${(state.has_more) ? '&after='+state.after : ''}`
            );

            let currentInvoices = state.data;
            commit('setAfter',invoices[invoices.length-1].id);
            commit('setInvoices',currentInvoices.concat(invoices));
            commit('setHasMore', has_more);

        } catch(error){
            console.error(error.message);
            return false;
        }
    },
    async fetchInvoice(state, {id}){
        try{
            let {data: { data: invoice} } = await API.get(  
                `billing/invoices/${id}`
            );
            
            return invoice;
        } catch(error){
            return false;
        }
    },
    resetInvoices({commit}){
        commit('setInvoices',[]);
        commit('setAfter',null);
        commit('setHasMore',null);
    }
};

const mutations = {
    setInvoices: (state, invoices)=>state.data = invoices,
    setAfter: (state, after)=>state.after = after,
    setHasMore: (state, bool)=>state.has_more = bool
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};