/* eslint-disable no-unused-vars */
import streamSaver from 'streamsaver';
import toast from '../plugins/toast';
import store from '../store';
import api from '../utils/api';
import * as AWS from 'aws-sdk';

const calculateDownloadSize = (transfer) => {
    return transfer.files.reduce((bytes, file)=>{
        return bytes + file.size;
    }, 0);
};

export default {

    async downloadBundle(transfer){
        toast.show(`${transfer.name} download starting...`, {type:'info',duration:5000});

        // let url = `${process.env.VUE_APP_API_DOWNLOAD_URL}?data=${transfer.id}&token=${token}`;
        // window.open(url);

        // api.patch(`transfers/${transfer.id}/downloads`, {});
    },

    async downloadSingle(file, transferID, updateTransfer){
        toast.show(`${file.name} download starting...`, {type:'info',duration:5000});

        let { data: s3 } = await api.get('s3/auth', {params:{permission:'GetObject'}});
        if(!s3.AccessKeyId){
            console.log('Error optaining S3 credentials');
            return false;
        }

        AWS.config.update({
            accessKeyId: s3.AccessKeyId,
            secretAccessKey: s3.SecretAccessKey,
            sessionToken: s3.SessionToken,
            region:process.env.VUE_APP_S3_REGION, 
            endpoint: new AWS.Endpoint(process.env.VUE_APP_S3_ENDPOINT),
            s3ForcePathStyle: true, // needed with minio?
            signatureVersion: 'v4'
          });

        let S3 = new AWS.S3();

        let signedUrl = S3.getSignedUrl('getObject',{ Bucket: file.s3.bucket, Key: file.s3.key });

        window.open(signedUrl);
        
        if(transferID){
            let {data: transfer} = await api.patch(`transfers/${transferID}/downloads`, {});
            if(transfer.data){
                updateTransfer(transfer.data);
                console.log('updated');
            }
        }
    }

};