import Vue from 'vue';
import VueToast from 'vue-toast-notification';
// Import one of available themes
import 'vue-toast-notification/dist/theme-default.css';
//import 'vue-toast-notification/dist/theme-sugar.css';
 
Vue.use(VueToast, {
    position: 'bottom-left',
    duration: 15000,
    dismissible: true,
    type: 'default',
    pauseOnHover: true,
    queue: true
});

const toast = {
    show(message, options = {}){
        return Vue.$toast.open({
            message,
            ...options
        });
    },
    clear(){
        Vue.$toast.clear();
    }
};

export default toast;