<template>
  <div id="nav">
    <section id="logo">
      <router-link data-id="app_logo" to="/">
        <img class="logo" :src="images.logo" alt="Anyfile Logo">
      </router-link>
    </section>
    <section id="links">
      <router-link data-id="main_nav_home" v-if="isAuth()" to="/">Home</router-link>
      <router-link data-id="main_nav_transfers" v-if="isAuth()" to="/transfers">Transfers</router-link>
      <router-link data-id="main_nav_plans" to="/plans">Plans</router-link>
      <router-link data-id="main_nav_about" to="/about">About</router-link>
      <router-link data-id="main_nav_help" to="#">Help</router-link>
      <router-link v-if="!isAuth()" to="/login" class="login-btn">Login</router-link>
      <UserContextMenu v-else />
    </section>
  </div>
</template>

<script>
import UserContextMenu from './UserContextMenu';
import logo from '../assets/logo.svg';
import { mapGetters } from 'vuex';
export default {
  data(){
    return {
      images: {
        logo
      }
    };
  },
  components: {
    UserContextMenu,
  },
  methods: mapGetters('user', ['isAuth']),
};
</script>

<style scoped>
#nav {
  padding: 5px 30px;
  text-align: right;
  position: relative;
  z-index: 9999999999 !important;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
}
section#links {
  padding-top: 12px;
}
#nav a {
  font-weight: 400;
  text-decoration: none;
  color: #112445;
  padding-left: 20px;
  text-transform: uppercase;
}

#nav a.router-link-exact-active {
  font-weight: 700;
}

#nav a.login-btn {
  background-color: #0f2748;
  color: #fff !important;
  padding: 10px 20px;
  margin-left: 30px;
  border-radius: 50px;
  font-weight: 600 !important;
  border: unset;
}
#nav section#logo h1 {
  margin: 0;
  padding: 0;
  display: inline-block;
  left: 20px;
  margin-top: -5px;
  position: absolute;
}
img.logo {
    width: 170px;
    top: 4px;
    position: relative;
}
section#logo a {
    padding-left: 0 !important;
}
</style>