import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store';

Vue.use(VueRouter);

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth:true,
      title: 'Anyfile'
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      auth:false,
      title: 'About | Anyfile'
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      auth:false,
      title: 'Log in | Anyfile'
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Signup.vue'),
    meta: {
      auth:false,
      title: 'Sign up | Anyfile'
    }
  },
  {
    path: '/payment-methods',
    name: 'Payment Methods',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Billing.vue'),
    meta: {
      auth:true,
      title: 'Payment Methods | Anyfile'
    }
  },
  {
    path: '/account',
    name: 'Account',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Account.vue'),
    meta: {
      auth:true,
      title: 'Personal Details | Anyfile'
    }
  },
  {
    path: '/security',
    name: 'Security',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Security.vue'),
    meta: {
      auth:true,
      title: 'Password & Security | Anyfile'
    }
  },
  {
    path: '/invoices',
    name: 'Invoices',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Invoices.vue'),
    meta: {
      auth:true,
      title: 'Invoices | Anyfile'
    }
  },
  {
    path: '/transfers',
    name: 'Transfers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Transfers.vue'),
    meta: {
      auth:true,
      title: 'Transfers | Anyfile'
    }
  },
  {
    path: '/transfers/:id',
    name: 'Transfer Details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TransferDetails.vue'),
    meta: {
      auth:true,
      title: 'Transfer Details | Anyfile'
    }
  },
  {
    path: '/plans',
    name: 'Plans',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Upgrade.vue'),
    meta: {
      auth:false,
      title: 'Plans | Anyfile'
    }
  },
  {
    path: '/checkout',
    name: 'Checkout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Checkout.vue'),
    meta: {
      auth:true,
      title: 'Checkout | Anyfile'
    }
  },
  {
    path: '*',
    name: '404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue'),
    meta: {
      auth:false,
      title: 'Page not found | Anyfile'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if(to.path == '/login' || to.path == '/signup'){
    store.commit('user/setError', null);
  }
  if(to.path == '/login' && store.getters['user/isAuth']){
    return next({
      path: to.query.redirect || '/'
    });
  }
  if (to.meta.auth && !store.getters['user/isAuth']) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    });
  }
  let subscription = store.getters.getSubscription;
  if (to.meta.subscribed && (!subscription || !['active','trialing'].includes(subscription.status)) ) {
    return next({
      path: '/plans',
      query: { from: to.path }
    });
  }

  document.title = to.meta.title;
  next();
  
});

export default router;
