import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import transfers from './modules/transfers';
import files from './modules/files';
import plans from './modules/plans';
import upload from './modules/upload';
import invoices from './modules/invoices';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    transfers,
    plans,
    upload,
    invoices,
    files
  }
});
