<template>
  <div id="500" class="main-section">
    <Background v-bind:backgroundSrc="backgroundSrc"/>
    <div id="page-content">
      <div class="backdrop">
        <section id="inner-container">
            <h1>500</h1>
            <p>Sorry, seems we had an unexpected problem</p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Background from '../components/Background/Background';

export default {
  name: 'error500',
  components: {
      Background
  },
  data(){
      return {
            backgroundSrc: 'Misc.svg',
      };
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin:0;
  padding:0
}
#page-content {
  font-family: ProximaNova, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #102445;
  max-width:1600px;
  padding:20px;
  margin:0 auto;
  position: relative;
  z-index: 0;
  padding-bottom: 0;
}
.backdrop {
    background-color: #FFF;
    border-radius: 63px;
    max-height: calc(100vh + -200px);
    min-height: 600px;
    height: 0px;
    max-width: 1250px;
    margin: 0 auto;
    position: relative;
    padding: 50px 100px;
}
.backdrop {
    box-shadow: 0 0 10px rgba(0,0,0,0.08);
    position:relative;
}
.backdrop h1 {
    font-size: 250px;
    text-align: center;
    line-height: 250px;
}
.backdrop p {
    font-size: 37px;
    margin-top: -26px;
}
#inner-container{
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -55%);
}
</style>