<template>
  <footer>
      <section id="left">
          Copyright &copy; {{(new Date()).getFullYear()}} Anyfile LLC
      </section>
      <section id="right">
          <a data-id="footer_nav_twitter" href="https://twitter.com/AnyfileLLC" target="_blank"><img :src="icons.twitter" alt=""></a>
          <a data-id="footer_nav_facebook" href="https://facebook.com/AnyfileLLC" target="_blank"><img :src="icons.facebook" alt=""></a>
          <a data-id="footer_nav_instagram" href="https://instagram.com/AnyfileLLC" target="_blank"><img :src="icons.instagram" alt=""></a>
          <a data-id="footer_nav_youtube" href="https://youtube.com/AnyfileLLC" target="_blank"><img :src="icons.youtube" alt=""></a>
      </section>
  </footer>
</template>

<script>
import twitter from '@/assets/Social/twitter.svg';
import facebook from '@/assets/Social/facebook.svg';
import instagram from '@/assets/Social/instagram.svg';
import youtube from '@/assets/Social/youtube.svg';
export default {
    data(){
        return {
            icons: {
                twitter,
                facebook,
                instagram,
                youtube
            }
        };
    }
};
</script>

<style>
footer{
    position:relative;
    z-index:1;
}
footer section{
    width:50%;
    float:left;
    height:60px;
}
footer section#left {
    text-align: left;
    padding: 20px;
}
footer section#right {
    text-align: right;
    padding: 20px;
}
footer section#right a:not(:nth-child(1)){
    margin-left:30px;
}
</style>