<template>
  <div id="user-context-menu-container">
    <div id="user-context-menu" data-id="context_menu">
      <section id="user-avatar" ref="menu-toggle">
        <img :src="getUser().app && getUser().app.picture ? getUser().app.picture : (getUser().photoURL || avatarPlaceholder)" alt />
      </section>
    </div>
    <div
      class="menu"
      :class="{visible: open}"
      v-closable="{
            exclude: ['menu'],
            toggle: 'menu-toggle',
            handler: 'toggleMenu',
            _if: '.menu.visible'
        }"
      ref="menu"
    >
      <li v-for="menu in menus" :key="menu.id">
        <router-link :data-id="menu.data_id" v-on:click.native="toggleMenu()" ref="menu" v-if="menu.link" :to="menu.link">{{menu.text}}</router-link>
        <a v-else :data-id="menu.data_id" href="javascript:;" @click="menu.method()">{{menu.text}}</a>
        <!-- <a
          :href="menu.method?'javascript:;':menu.link"
          @click="menu.method?menu.method():''"
        >{{menu.text}}</a> -->
      </li>
      <!-- <li><a href="javascript:;" @click="signOut">Log out</a></li> -->
    </div>
    <span></span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import avatarPlaceholder from '../assets/avatar_placeholder.jpeg';
import Vue from 'vue';

// This variable will hold the reference to
// document's click handler
let handleOutsideClick;

Vue.directive('closable', {
  bind(el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation();
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude, toggle, _if } = binding.value;

      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName];
          // See if this excluded element
          // is the same element the user just clicked on
          clickedOnExcludedEl = excludedEl.contains(e.target);
        }
      });

      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If toggle was clicked
        let toggleClicked = vnode.context.$refs[toggle].contains(e.target);
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        if (document.querySelector(_if) || toggleClicked)
          vnode.context[handler]();
      }
    };
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
  },

  unbind() {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick);
  },
});

export default {
  methods: {
    ...mapGetters('user',['getUser']),
    ...mapActions('user',['signOut']),
    toggleMenu() {
      this.open = !this.open;
    },
  },
  data() {
    return {
      open: false,
      avatarPlaceholder,
      menus: [
        {
          id: 0,
          text: 'My Account',
          data_id: 'context_menu_my_account',
          link: '/account',
        },
        {
          id: 1,
          text: 'Password and Security',
          data_id: 'context_menu_password_security',
          link: '/security',
        },
        {
          id: 2,
          text: 'Payment Methods',
          data_id: 'context_menu_payment_methods',
          link: '/payment-methods',
        },
        {
          id: 3,
          text: 'Invoices',
          data_id: 'context_menu_invoices',
          link: '/invoices',
        },
        // {
        //   id: 4,
        //   text: 'My Subscription',
        //   link: '/my-subscription',
        // }
        {
          id: 5,
          text: 'Log out',
          data_id: 'context_menu_logout',
          method: this.signOut,
        },
      ],
    };
  },
};
</script>

<style scoped>
#user-avatar {
  cursor: pointer;
}
.menu {
  display: none;
  width: max-content;
  min-width: 160px;
  padding: 5px 0;
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% + 10px));
  right: 0;
  background-color: #fff;
  border-radius: 5px;
}
.menu li {
  list-style-type: none;
}
.menu li:hover {
  background-color: #e8e8e8;
}
.menu li a {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 40px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
  color: #222;
}
.menu.visible {
  display: block;
  border: 1px solid #ccccdb;
}
#user-context-menu-container {
  width: 40px;
  height: 40px;
  float: right;
  top: -12px;
  position: relative;
  margin-left: 20px;
  margin-bottom: -12px;
}
#user-context-menu {
  width: 40px;
  height: 40px;
  float: right;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #1d2f4d;
}
#user-context-menu img {
  width: 100%;
}
a.router-link-exact-active.router-link-active {
    background-color: #CCCCDB;
}
</style>