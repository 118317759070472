<template>
    <div id="background-component" :style="'background-image:Url('+require(`@/assets/BackgroundImages/${backgroundSrc}`)+')'" class="src"></div>
</template>

<script>
export default {
    name: 'BackgroundContent',
    props: ['backgroundSrc']
};
</script>

<style scoped>
    #background-component {
        position:fixed;
        top:0;
        left:0;
        width:100vw;
        height:100vh;
        background-size: cover;
        background-position:center center;
        z-index:-1;
    }
</style>