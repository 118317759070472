import api from '../../utils/api';

const getCookie = (name) => {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
};

const state = {
    sent: [],
    received: [],
    pagination: {
        sent: {
            currentPage: 0,
            nextPage:null
        },
        received: {
            currentPage: 0,
            nextPage:null
        }
    }
    
};

const getters = {
    isEmpty: (state, type) => (!state.transfer || !state.transfer[type] && true || false)
};

const actions = {
    async fetchSentTransfers({commit}, {sort, search, page}){
        try{
            let {data: { data: transfers, pagination} } = await api.get(  
                `transfers?sort=${sort}${(search) ? '&search='+search : ''}${(page) ? '&page='+page : ''}`
            );

            let currentTransfers = state.sent;
            if(pagination.current_page == 1){
                commit('setCurrentSentPage',pagination.current_page);
                commit('setNextSentPage',pagination.next_page);
                commit('setSentTransfers',transfers);
            } else {
                commit('setCurrentSentPage',pagination.current_page);
                commit('setNextSentPage',pagination.next_page);
                commit('setSentTransfers',currentTransfers.concat(transfers));
            }
        } catch(error){
            console.error(error.message);
            return false;
        }
    },
    async fetchReceivedTransfers({state, commit}, {sort, search, page}){
        try{
            let {data: { data: transfers, pagination} } = await api.get(  
                `transfers?type=received&sort=${sort}${(search) ? '&search='+search : ''}${(page) ? '&page='+page : ''}`
            );
            let currentTransfers = state.received;
            if(pagination.current_page == 1){
                commit('setCurrentReceivedPage',pagination.current_page);
                commit('setNextReceivedPage',pagination.next_page);
                commit('setReceivedTransfers',transfers);
            } else {
                commit('setCurrentReceivedPage',pagination.current_page);
                commit('setNextReceivedPage',pagination.next_page);
                commit('setReceivedTransfers',currentTransfers.concat(transfers));
            }
        } catch(error){
            return false;
        }
    },
    async fetchTransfer(state, {id}){
        try{
            // Check for passwords in cookies
            let pwd = getCookie(id);
            let {data: { data: transfer} } = await api.get(  
                `transfers/${id}?password=${pwd}`
            );
            
            return transfer;
        } catch(error){
            return false;
        }
    },
    replaceTransfer({state, commit}, {transfer}){
        commit('setSentTransfers',state.sent.map(t=>{
            if(t.id==transfer.id){
                return transfer;
            }
            return t;
        }));
        commit('setReceivedTransfers',state.received.map(t=>{
            if(t.id==transfer.id){
                return transfer;
            }
            return t;
        }));
    },
    removeTransfer({state, commit}, {id}){
        commit('setSentTransfers',state.sent.filter(t=> t.id!=id));
        commit('setReceivedTransfers',state.received.filter(t=> t.id!=id));
    },
};

const mutations = {
    setSentTransfers: (state, transfers)=>state.sent = transfers,
    setReceivedTransfers: (state, transfers)=>state.received = transfers,
    setCurrentSentPage: (state, currentPage)=>state.pagination.sent.currentPage = currentPage,
    setNextSentPage: (state, nextPage)=>state.pagination.sent.nextPage = nextPage,
    setCurrentReceivedPage: (state, currentPage)=>state.pagination.received.currentPage = currentPage,
    setNextReceivedPage: (state, nextPage)=>state.pagination.received.nextPage = nextPage,
};

export default {
    state,
    getters,
    actions,
    mutations
};