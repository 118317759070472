import api from '../../utils/api';

const state = [
    {
        product_id: 'free',
        title: 'Anyfile Free',
        price: 'Free forever<span class="spacer"></span>',
        highlights: [
          {
            icon: 'x-circle.svg',
            text: 'No storage'
          },
          {
            icon: 'check-circle.svg',
            text: 'Receive files of any size'
          }
        ],
        button: {
          text: 'Sign up for free',
          style: 'inverted'
        }
    },
    {
        product_id: 'prod_HjA08xyaTgwWJU',
        trial_available: true,
        prices: [
          {
            term: 'monthly',
            price: 10,
            plan_id: 'price_1HT9IDGigmF4YGKC1gv2xDVS',
            remarks: '(Billed Monthly)'
          },
          {
            term: 'yearly',
            price: 100,
            plan_id: 'price_1HT9BhGigmF4YGKCXXcYgzlZ',
            remarks: '8% off (Billed Yearly)'
          }
        ],
        title: 'Anyfile Starter',
        price: '<span class="prefix">Starting at</span> <span class="value">$10</span><span class="currency">USD</span> <span class="term">/Month</span>',
        highlights: [
          {
            icon: 'check-circle.svg',
            text: '1 TB of storage'
          },
          {
            icon: 'check-circle.svg',
            text: '20 email recepient'
          },
          {
            icon: 'check-circle.svg',
            text: 'Send and Receive files of any size'
          },
          {
            icon: 'check-circle.svg',
            text: 'Password protect your transfers'
          }
        ],
        button: {
          text: 'Get Anyfile Starter',
          style: 'normal'
        }
    },
    {
        product_id: 'prod_HjAWh49kWwwhVf',
        prices: [
          {
            term: 'monthly',
            price: 60,
            plan_id: 'price_1KxFjHGigmF4YGKCyIEo3uS3',
            remarks: '(Billed Monthly)'
          },
          {
            term: 'yearly',
            price: 600,
            plan_id: 'price_1KxFjUGigmF4YGKCVUVbaQAi',
            remarks: '8% off (Billed Yearly)'
          }
        ],
        title: 'Anyfile Pro',
        price: '<span class="prefix">Starting at</span> <span class="value">$60</span><span class="currency">USD</span> <span class="term">/Month</span>',
        highlights: [
          {
            icon: 'check-circle.svg',
            text: '5 TB of storage'
          },
          {
            icon: 'check-circle.svg',
            text: 'Unlimited email recepient'
          },
          {
            icon: 'check-circle.svg',
            text: 'Send and Receive files of any size'
          },
          {
            icon: 'check-circle.svg',
            text: 'Password protect your transfers'
          }
        ],
        button: {
          text: 'Get Anyfile Pro',
          style: 'normal'
        }
    }
];

const getters = {
    getPlans: state => state
};

const actions = {
     fetchPlans: async ({commit}) => {
        let {data: { data: plans} } = await api.get(  
            'plans'
        );
        commit('setPlans', plans);
    }
};

const mutations = {
    setPlans: (state, plans) => state=plans
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};