<template>
 <div>
    <BackgroundContent v-bind:backgroundSrc="backgroundSrc"/>
 </div>
</template>

<script>

import BackgroundContent from './Default';

export default {
    name: 'Background',
    components: {
        BackgroundContent
    },
    props:['backgroundSrc']
};
</script>

<style>
</style>