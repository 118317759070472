import Vue from 'vue';
import Error500 from '../views/500.vue';
import store from '../store';

export default async function(){
    await store.commit('user/setLoading',false);
    await store.commit('user/setUser',null);
    await store.commit('user/setToken',null);
    document.title = 'Encountered an error | Anyfile';
    return new Vue({
        store,
        render: h => h(Error500)
    }).$mount('#app');
}