import store from '../store';
import axios from 'axios';

const getToken = async () => {
    return await store._actions['user/getToken'][0]();
};

const apiClent = async () => {
    let token = await getToken();
    let apiClient = axios.create({
        baseURL: process.env.VUE_APP_API_BASE,
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        withCredentials: true
    });

    return apiClient;
};

export default {

    async get(uri, options){
        let api = await apiClent();
        return api.get(uri, options);
    },
    async post(uri, body){
        let api = await apiClent();
        return api.post(uri, body);
    },
    async patch(uri, body){
        let api = await apiClent();
        return api.patch(uri, body);
    },
    async put(uri, body){
        let api = await apiClent();
        return api.put(uri, body);
    },
    async delete(uri){
        let api = await apiClent();
        return api.delete(uri);
    },

};