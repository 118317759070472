import firebase from '@/plugins/firebase';
import router from '@/router';
import AppError from '@/utils/error';
import api from '../../utils/api';

const state = {
    user: null,
    error: null,
    token: null,
    loading: true
};

const getters = {
    isAuth: (state) => (state.user && true || false),
    getUser: (state) => state.user,
    getError: (state) => state.error,
    getSubscription: (state) => {
        return state.user && state.user.app && state.user.app.stripe_customer.subscriptions && state.user.app.stripe_customer.subscriptions.data.length ? state.user.app.stripe_customer.subscriptions.data[0] : null;
    },
    isLoading: (state) => state.loading
};

const actions = {
    signInGoogle({ commit }){
        let provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        firebase.auth().useDeviceLanguage();
        firebase.auth().signInWithPopup(provider)
        .then(response => {
            commit('setUser',response.user);
            commit('setError', null);
            router.push(router.currentRoute.query.redirect || '/');
        })
        .catch(error => {
            commit('setError',error);
        });
    },
    signInFacebook({ commit }){
        let provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().useDeviceLanguage();
        firebase.auth().signInWithPopup(provider)
        .then(response => {
            commit('setUser',response.user);
            commit('setError', null);
            router.push(router.currentRoute.query.redirect || '/');
        })
        .catch(error => {
            commit('setError',error);
        });
    },
    signInTwitter({ commit }){
        let provider = new firebase.auth.TwitterAuthProvider();
        firebase.auth().useDeviceLanguage();
        firebase.auth().signInWithPopup(provider)
        .then(response => {
            commit('setUser',response.user);
            commit('setError', null);
            router.push(router.currentRoute.query.redirect || '/');
        })
        .catch(error => {
            commit('setError',error);
        });
    },
    signInEmail({ commit }, user){
        // let provider = new firebase.auth.TwitterAuthProvider();
        // firebase.auth().useDeviceLanguage();
        firebase.auth().signInWithEmailAndPassword(user.email, user.password)
        .then(response => {
            commit('setUser',response.user);
            commit('setError', null);
            router.push(router.currentRoute.query.redirect || '/');
        })
        .catch(error => {
            commit('setError',error);
        });
    },
    signUpEmail({ commit }, user){
        // let provider = new firebase.auth.TwitterAuthProvider();
        // firebase.auth().useDeviceLanguage();
        firebase.auth().createUserWithEmailAndPassword(user.email, user.password)
        .then(async response => {
            let newUser = await firebase.auth().currentUser;
            newUser.updateProfile({
                displayName: user.name
            })
            .then(()=>{
                commit('setUser',response.user);
                commit('setError', null);
                router.push(router.currentRoute.query.redirect || '/');
            })
            .catch(error=>{
                commit('setError',error);
            });
        })
        .catch(error => {
            commit('setError',error);
        });
    },
    async checkAuthState({ commit }) {
        let checkState = new Promise((resolve,reject) => {
            firebase.auth().onAuthStateChanged(user => resolve(user), error => reject(error));
        });

        await checkState
        .then(async user=>{
            if (user) {
                let dbUser = await api.get('users/me');
                commit('setUser', {...user, app: dbUser.data});
            } else {
                commit('setUser', null);
            }
        })
        .catch(error=>{
            AppError(error);
            commit('setError', error);
        });
    },
    signOut({ commit }){
        firebase.auth().signOut();
        commit('setUser', null);
        router.push('/login');
    },
    setAppUser({ commit }, user) {
        commit('setAppUser', user);
    },
    getToken: async ({commit, state}) => {
        try{
            let token = state.token;
            let exp = token && JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf-8')).exp || 0;
            let now = Math.floor((new Date().getTime())/1000);

            if(!exp || exp-600 < now){
                token = await firebase.auth().currentUser.getIdToken(true).then(id=>id);
                commit('setToken', token);
                return token;
            } else {
                return state.token;
            }
        } catch(err){
            await AppError(err);
        }

    },
    setError: ({commit}, error) => {
        commit('setError', error);
    }
};

const mutations = {
    setUser: (state, user) => state.user = user,
    setError: (state, error) => state.error = error,
    setAppUser: (state, user) => state.user.app = user,
    setToken: (state, token) => state.token = token,
    setLoading: (state, loading) => state.loading = loading
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};