<template>
  <img :src="getIcon($props.type)" @click="$emit('click')" :class="$props.class"/>
</template>

<script>
import FOLDER_ICON from '@/assets/FileIcon/folder.png';
import IMAGE_ICON from '@/assets/FileIcon/picture.png';
import VIDEO_ICON from '@/assets/FileIcon/film.png';
import PDF_ICON from '@/assets/FileIcon/pdf.png';
import DOC_ICON from '@/assets/FileIcon/doc.png';
import ZIP_ICON from '@/assets/FileIcon/zip-file.png';
import OTHER_ICON from '@/assets/FileIcon/file.png';
import DRIVE from '@/assets/FileIcon/harddisk.png';
export default {
    name: 'FileIcon',
    props: ['type'],
    data(){
        return {
            icons: {
                '^folder$': FOLDER_ICON,
                '^drive$': DRIVE,
                '^image\\/': IMAGE_ICON,
                '^video\\/': VIDEO_ICON,
                '^application\\/zip$': ZIP_ICON,
                '^application\\/pdf$': PDF_ICON,
                '(?:^text\\/(rtf$)$)|(^application\\/(.*)(?:msword|officedocument\\.word|opendocument\\.text))': DOC_ICON,
                '^': OTHER_ICON
            }
        };
    },
    methods: {
        getIcon(type){
            let matched = '';
            for(let key in this.icons){
                if(matched) continue;
                let expression = new RegExp(key);
                if(expression.test(type)) matched = key;
            }
            return this.icons[matched];
        }
    }
};
</script>