<template>
  <div id="file-manager" @contextmenu.prevent :class="{initializing}">
    <vue-simple-context-menu
      element-id="file-context-menu"
      :options="contextMenuOptions"
      ref="fileContextMenu"
      @option-clicked="contextAction"
    />
    <section v-if="initializing" class="loading"> 
      <Loading />
    </section>
    <section v-else id="file-manager-wrapper">
      <article id="file-manager-sidebar">
        <div class="usage">
          <FileIcon type='drive'></FileIcon>
          <section>
            <h3>Storage</h3>
            <div class="progress-bar">
              <progress :max="getUsage().capacity" :value="getUsage().used"></progress>
            </div>
            <div class="bytes-used">{{ fileSizeFormatted(getUsage().used) }} of {{ fileSizeFormatted(getUsage().capacity) }} used</div>
          </section>
        </div>
        <div class="get-more-storage">
          <button class="get-more">Get More Storage</button>
        </div>
      </article>
      <article id="file-manager-container">
        <section id="file-manager-actions">
          <div id="file-manager-actions-breadcrumbs">
            <button
              :disabled="crumb.folder == viewing" 
              @click="()=>changeViewing(crumb)" 
              v-for="crumb in breadcrumbs()" 
              :key="crumb.folder"
              @drop.prevent="()=>fileDropHandler(crumb.folder)" 
              @dragenter.prevent 
              @dragover.prevent
            >
                {{crumb.label}}
            </button>
          </div>
          <div id="file-manager-actions-search">
            <input type="text" placeholder="Search Files" @input="startFileSearch" v-model="searchQuery"/>
            <fa-icon class="searching-icon" :icon="['fas','spinner']" spin v-if="searching"/>
            <fa-icon class="clear-search-icon" :icon="['fas','undo']" v-if="activeSearch && !searching" @click="initFileManager" v-tooltip="'Clear Search Results'"/>
          </div>
          <div id="file-manager-actions-management">
            <button class="file-manager-actions-button" @click="toggleIconSize" v-tooltip="'Change Icon Size'">
              <fa-icon class="action-icon" :icon="['fas','th-large']" />
            </button>
            <button class="file-manager-actions-button" @click="newFolder" v-tooltip="'New Folder'">
              <fa-icon class="action-icon" :icon="['fas','folder-open']" />
            </button>
            <button class="file-manager-actions-button" @click="toggleOpen" v-tooltip="'New File'">
              <fa-icon class="action-icon" :icon="['fas','file-alt']" />
            </button>
            <button class="file-manager-actions-button" @click="initFileManager" v-tooltip="'Reset File Manager'">
              <fa-icon class="action-icon" :icon="['fas','refresh']" />
            </button>
          </div>
        </section>
        <section id="files-container">
          <v-popover 
            openClass="delete-file-confirm"
            :open="!!deletingFile"
            @hide="()=>deletingFile=null"
            trigger="manual"
          >
              <section slot="popover" class="delete-form">
                  <section>
                      <label>Are you sure you want to delete this file?</label>
                  </section>
                  <button id="forward-submit-btn" @click="()=>deleteFile(deletingFile)">Delete</button>
              </section>
          </v-popover>
          <NoFilesFound v-if="!visibleFiles.length && ! visibleFolders.length"/>
          <div id="files-container-inner" :class="`grid-size-${activeIconSize}`">
            <!-- Display Folders -->
            <div 
              class="files-container-item" 
              :class="{pending: folder.pending}" 
              v-for="folder in visibleFolders" 
              :key="folder.folder" 
              @drop.prevent="()=>fileDropHandler(folder.folder)" 
              @dragenter.prevent 
              @dragover.prevent
              @contextmenu.prevent.stop="(e)=>openContextMenu(e, {folder})"
            >
              <section class="files-container-item-icon" draggable="false">
                <button class="files-container-item-delete-temp" v-tooltip="'Remove temporary folder'" v-if="folder.pending" @click="()=>deletePendingFolder(folder)"><fa-icon :icon="['fas','times']" /></button>
                <FileIcon type='folder' @click="()=>changeViewing(folder)" draggable="false"/>
              </section>
              <section 
                class="files-container-item-name"
              >
                <div v-if="nameToUpdate && nameToUpdate.folder && nameToUpdate.folder.folder == folder.folder">
                  <input v-model="nameToUpdate.name" @keyup="cancelNameChange" class="files-container-item-name-edit"/>
                  <button class="files-container-item-name-edit-save" @click="finishNameChange">
                    <fa-icon :icon="['fas','check']" />
                  </button>
                  <button class="files-container-item-name-edit-cancel" @click="(e)=>cancelNameChange(e, true)">
                    <fa-icon :icon="['fas','times']" />
                  </button>
                </div>
                <div 
                  v-else
                  @click="()=>startNameChange({folder})"
                >
                  {{`${truncateItemName(folder.label)}`}}
                </div>
              </section>
            </div>
            <!-- Display Files -->
            <div 
              class="files-container-item" 
              v-for="file in visibleFiles" 
              :key="file.id" 
              :data-id="file.id" 
              :draggable="!(nameToUpdate && nameToUpdate.file && nameToUpdate.file.id == file.id)" 
              @dragstart="fileDragStarted"
              @contextmenu.prevent.stop="(e)=>openContextMenu(e, {file})"
            >
              <section class="files-container-item-icon" draggable="false">
                <FileIcon :type="file.type" draggable="false"/>
              </section>
              <section class="files-container-item-name" 
                draggable="false"
              >
                <div v-if="nameToUpdate && nameToUpdate.file && nameToUpdate.file.id == file.id">
                  <input draggable="false" v-model="nameToUpdate.name" @keyup="cancelNameChange" class="files-container-item-name-edit"/>
                  <button class="files-container-item-name-edit-save" @click="finishNameChange">
                    <fa-icon :icon="['fas','check']" />
                  </button>
                  <button class="files-container-item-name-edit-cancel" @click="(e)=>cancelNameChange(e, true)">
                    <fa-icon :icon="['fas','times']" />
                  </button>
                </div>
                <div 
                  v-else
                  @click="()=>startNameChange({file})"
                >
                  {{`${truncateItemName(file.name)}`}}
                </div>
              </section>
            </div>
          </div>
        </section>
      </article>
    </section>
  </div>
</template>

<script>
import '../icons';
import { mapState, mapActions } from 'vuex';
import Loading from '../components/Loading';
import FileIcon from '../components/FileIcon';
import NoFilesFound from '../components/NoFilesFound';
import toast from '../plugins/toast';
import VueSimpleContextMenu from 'vue-simple-context-menu';
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';
import VTooltip from 'v-tooltip';
import Vue from 'vue';
import file from '../utils/file';
Vue.use(VTooltip);

export default {
  data(){
    return {
      viewing: '/',
      initializing: true,
      visibleFiles: [],
      visibleFolders: [],
      activeIconSize: 'small',
      fileToUpdate: '',
      nameToUpdate: null,
      contextMenuOptions: [],
      deletingFile: null,
      debouncedSearch: 0,
      searchQuery: '',
      searching: false,
      activeSearch: false
    };
  },
  components: {
    Loading,
    FileIcon,
    NoFilesFound,
    VueSimpleContextMenu
  },
  methods: {
    ...mapActions('files',['fetchFileManagerData','moveFile','renameFolder','renameFile','removeFile','searchFiles']),
    ...mapActions('upload',['toggleOpen']),
    async initFileManager(){
      this.initializing = true;
      this.searchQuery = '';
      this.searching = false;
      this.debouncedSearch = 0;
      this.activeSearch = false;
      await this.fetchFileManagerData();
    },
    updateFileManager(){
      this.getCurrentFolderFiles();
      this.getCurrentFolderFolders();
      this.initializing = false;
    },
    getCurrentFolderFiles(){
      let currentFolder = this.files.fileManager.find(f=>f._id==this.viewing);
      this.visibleFiles = currentFolder?.files || [];
    },
    getCurrentFolderFolders(){
      let folderPaths = [];
      let visibleFolders = [];

      for(let folder of this.files.fileManager){
        if(folder._id.indexOf(this.viewing) !== -1 && folder._id != this.viewing){
          let pathTest = folder._id.replace(this.viewing, '/').split('/').filter(s=>s!=='');
          if(pathTest.length) folderPaths.push(this.viewing+pathTest[0]+'/');
        }
      }

      // Check locally persisted temporary folders
      let pendingFolders = JSON.parse(localStorage.getItem('pendingFolders') || '[]');

      // Check stale peding folders
      pendingFolders = pendingFolders.filter(f=>{
        if(!folderPaths.includes(f.folder)) return true;
      });
      localStorage.setItem('pendingFolders', JSON.stringify(pendingFolders));

      for(let folder of pendingFolders){
        if(folder.folder.indexOf(this.viewing) !== -1 && folder.folder != this.viewing){
          let pathTest = folder.folder.replace(this.viewing, '/').split('/').filter(s=>s!=='');
          if(pathTest.length) folderPaths.push('!'+this.viewing+pathTest[0]+'/');
        }
      }

      let folderPathsDeduped = Array.from(new Set(folderPaths));
      for(let folder of folderPathsDeduped){
        let pending = false;
        if(folder.indexOf('!') === 0){
          pending = true;
          folder = folder.replace('!','');
        }
        let deconstructedPath = folder.split('/').filter(s=>s!=='');

        visibleFolders.push({
          label: deconstructedPath[deconstructedPath.length-1],
          folder,
          pending
        });
      }
      this.visibleFolders = visibleFolders;
    },
    breadcrumbs() {
      let crumbs = [{
        label: 'My Drive',
        folder: '/'
      }];

      if(this.viewing !== '/') {
        let folders = this.viewing.split('/').filter(s=>s!=='');
        let combinedPath = '/';
        folders.forEach(f=>{
          combinedPath = combinedPath+f+'/';
          crumbs.push({
            label: f,
            folder: combinedPath
          });
        });
      }
      return crumbs;
    },
    changeViewing(crumb){
      this.viewing = crumb.folder;
    },
    truncateItemName(name){
      const sizes = {
        small: 10,
        medium: 20,
        large: 40
      };
      if(name.length <= (sizes[this.activeIconSize]*2)+3) return name;
      return name.substring(0, sizes[this.activeIconSize])+'...'+name.substring(name.length - sizes[this.activeIconSize]);
    },
    toggleIconSize(){
      const sizes = ['small','medium','large'];
      const currentPos = sizes.findIndex(s=>s==this.activeIconSize);
      if(currentPos == sizes.length-1) this.activeIconSize = sizes[0];
      else this.activeIconSize = sizes[currentPos+1];
    },
    newFolder(){
      let postfix = 0;
      let availabilityFound = false;
      let availableFolderName;
      while(!availabilityFound) {
        availableFolderName = `${this.viewing}New Folder${postfix ? ` - ${postfix}` : ''}/`;
        let folderCheck = this.visibleFolders.find(f=>f.folder == availableFolderName);
        if(folderCheck) postfix+=1;
        else availabilityFound = true;
      }
      let folder = {
        label: `New Folder${postfix ? ` - ${postfix}` : ''}`,
        folder: availableFolderName,
        pending: true
      };

      // Persit in local session
      let pendingFolders = JSON.parse(localStorage.getItem('pendingFolders') || '[]');
      pendingFolders.unshift(folder);
      localStorage.setItem('pendingFolders', JSON.stringify(pendingFolders));
      this.updateFileManager();
    },
    newFile(){
      document.querySelector('#uploader-top-section section h4').click();
    },
    async fileDropHandler(folder){
      try {
        if(folder && this.fileToUpdate){
          let updateOp = this.fileToUpdate;
          this.fileToUpdate = '';
          await this.moveFile({fileId: updateOp, currentFolderId: this.viewing, newFolderId: folder});
        }
      } catch(err){
        toast.show(err.response?.data?.message || err.message || err,{type:'error'});
      }
    },
    fileDragStarted(e){
      if(e.target.classList.contains('files-container-item')){
        this.fileToUpdate = e.target.dataset.id;
      }
    },
    startNameChange({file, folder}){
      // if(!this.nameToUpdate){     
        this.nameToUpdate = {
          folder,
          file,
          name: folder?.label || file?.name
        };
      // }
    },
    async finishNameChange(){
      try {
        let changeConfig = this.nameToUpdate;
        this.nameToUpdate = false;
        if(!changeConfig) return;
        if(changeConfig?.folder){
          if(changeConfig.folder.label == changeConfig.name){
            changeConfig = false;
            console.log('No changes made');
            return;
          }
          // Update folder name
          if(changeConfig.folder.pending){
            let pendingFolders = JSON.parse(localStorage.getItem('pendingFolders') || '[]');
            pendingFolders = pendingFolders.map(f=>{
              if(f.folder == changeConfig.folder.folder){
                f.label = changeConfig.name;
                f.folder = `${this.viewing}${changeConfig.name}/`;
              }
              return f;
            });
            localStorage.setItem('pendingFolders', JSON.stringify(pendingFolders));
            this.updateFileManager();
          } else {
            await this.renameFolder({
              currentFolderId: changeConfig.folder.folder,
              newFolderId: `${this.viewing}${changeConfig.name}/`
            });
          }
        }
        if(changeConfig?.file){
          if(changeConfig.file.name == changeConfig.name){
            changeConfig = false;
            console.log('No changes made');
            return;
          }
          // Update file name
          await this.renameFile({
            folderId: this.viewing,
            fileId: changeConfig.file.id,
            newName: changeConfig.name    
          });
        }
        console.log(changeConfig);
        
      } catch(err){
        toast.show(err.response?.data?.message || err.message || err,{type:'error'});
      }
    },
    cancelNameChange(e, clicked){
      if(e.key == 'Enter') this.finishNameChange();
      if(clicked || e.key === 'Escape') this.nameToUpdate = false;
    },
    deletePendingFolder(folder){
      let pendingFolders = JSON.parse(localStorage.getItem('pendingFolders') || '[]');

      // Remove pending folder
      pendingFolders = pendingFolders.filter(f=>{
        if(folder.folder != f.folder) return true;
      });
      localStorage.setItem('pendingFolders', JSON.stringify(pendingFolders));
      this.updateFileManager();
    },
    openContextMenu(e, item){
      let options = [];
      if(item.folder){
        options = [
          { name: 'Rename' }
        ];
      }
      if(item.file){
        options = [
          { name: 'Rename' },
          { name: 'Download' },
          { name: '', type: 'divider'},
          { name: 'Delete', class: 'context-delete' }
        ];
      }
      this.contextMenuOptions = options;
      this.$refs.fileContextMenu.showMenu(e, item);
    },
    async deleteFile(options){
      this.deletingFile = null;
      await this.removeFile(options);
    },
    async contextAction(e){
      try {
        switch(e.option.name){
          case 'Delete':
            if(e.item.file)
              this.deletingFile = {fileId: e.item.file.id, folderId: this.viewing};
            if(e.item.folder)
              this.deletingFile = null;
            break;
          case 'Rename':
            this.startNameChange({
              folder: e.item.folder,
              file: e.item.file
            });
            break;
          case 'Download':
            await file.downloadSingle({
              name: e.item.file.name,
              s3: {
                key: e.item.file.key,
                bucket: e.item.file.bucket,
              }
            });
        }
      } catch(err){
        console.error(err);
        toast.show(err.response?.data?.message || err.message || err,{type:'error'});
      }
    },
    startFileSearch(){
      clearTimeout(this.debouncedSearch);
      this.debouncedSearch = setTimeout(async ()=>{
        try {
          if(!this.searchQuery) {
            if(this.activeSearch){
              await this.fetchFileManagerData();
              this.activeSearch = false;
            }
            return;
          }
          this.searching = true;
          await this.searchFiles(this.searchQuery);
          this.activeSearch = true;
          this.viewing = '/';
        } catch(err){
          console.error(err);
          toast.show(err.response?.data?.message || err.message || err,{type:'error'});
        } finally {
          this.searching = false;
        }
      }, 600);
    },
    getUsage(){
      return this.user.user?.app?.storage || {
        used: 0,
        capacity: 0
      };
    },
    fileSizeFormatted(size){
      if (!size) return '0 Bytes';
      let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      let i = parseInt(Math.floor(Math.log(size) / Math.log(1000)));
      if (i == 0) return size + ' ' + sizes[i];
      return (size / Math.pow(1000, i)).toFixed(1) + ' ' + sizes[i];
    }
  },
  computed: {
    ...mapState(['files','user']),
  },
  created () {
    this.initFileManager();
    
  },
  updated(){
      document.querySelector('body .files-container-item-name-edit')?.focus();
  },
  watch: {
    initializing(){
    },
    viewing(){
      this.updateFileManager();
    },
    'files.fileManager'(){
      this.updateFileManager();
    }
  }
};
</script>

<style scoped>
#file-manager {
  color: #102445;
  height: 100%;
  width: 100%;
  display: block;
  border-radius: 8px;
  overflow: hidden;
}
#file-manager.initializing{
  background: #ffffffeb;
}
#file-manager-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}
#file-manager-sidebar{
  width: 280px;
  height: 100%;
  background: #ffffffeb;
  margin-right: 5px;
}
#file-manager-container{
  height:100%;
  flex: 1;
  background: #ffffffeb;
}
.loading {
  height: 100%;
}
#files-container {
    overflow-x: scroll;
    height: calc(100% - 60px);
    top: 60px;
    width: 100%;
}
#files-container-inner {
    display: grid;
    grid-gap: 20px;
    padding: 15px 20px 20px;
}
.grid-size-small {
    grid-template-columns: repeat(auto-fill, 150px);
    grid-auto-rows: 120px;
}
.grid-size-medium {
    grid-template-columns: repeat(auto-fill, 300px);
    grid-auto-rows: 240px;
}
.grid-size-large {
    grid-template-columns: repeat(auto-fill, 450px);
    grid-auto-rows: 360px;
}
.files-container-item {
    display: grid;
    padding: 7px;
    border-radius: 10px;
    transition: background-color .2s ease-in-out;
    cursor: pointer;
    border: 1px solid #dadada;
}
.files-container-item:hover{
  background-color: #FFF;
}
.grid-size-small .files-container-item {
    grid-template-rows: 66% 34%;
}
.grid-size-medium .files-container-item {
    grid-template-rows: 83% 17%;
}
.grid-size-large .files-container-item {
    grid-template-rows: 88% 12%;
}
.files-container-item-icon {
    height: 100%;
    width: 100%;
    position:relative;
}
.files-container-item img{
  width: auto;
  height: 90%;
}
#file-manager-actions {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  border-bottom: 1px solid #dadada;
}

#file-manager-actions-breadcrumbs button {
  padding: 6px;
  font-size: 16px;
  background: unset;
  border: unset;
  color: #102445;
  font-weight: 500;
  margin-right: 10px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

#file-manager-actions-search {
  flex: 1;
  position: relative;
}
#file-manager-actions-breadcrumbs button:hover {
    background-color: #e6e6e6;
}

#file-manager-actions-breadcrumbs button:not(:last-of-type){
    color: #898989;
}

#file-manager-actions-breadcrumbs button:not(:first-of-type):before{
    content: '>';
    position:absolute;
    left: -10px;
    color: #898989;
}

#file-manager-actions-search input {
  width: 100%;
  padding: 9px;
  font-size: 16px;
  border: 1px solid #dadada;
  border-radius: 5px;
  color: #868686;
}

#file-manager-actions-management {
  min-width: 100px;
  display: flex;
  justify-content: right;
}

#file-manager-actions-search input::placeholder {
  color: #868686;
}
#no-found-container{
  height: calc(100% - 60px);
}
.file-manager-actions-button {
    padding: 6px;
    font-size: 16px;
    background: unset;
    border: unset;
    color: #102445;
    font-weight: 500;
    margin-left: 10px;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}
.file-manager-actions-button:hover {
    background-color: #e6e6e6;
}
.files-container-item.pending {
    background-color: #7e000021;
}
.files-container-item-name {
    word-break: break-all;
}
.files-container-item-name-edit {
    width: 100%;
    padding: 3px;
    background: unset;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 30px;
}
.files-container-item-name div {
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.files-container-item-name button {
    background: unset;
    border: 1px solid #ccc;
    border-radius: 2px;
    width: 23px;
    font-size: 9px;
    margin-left: 2px;
    transition: background .2s ease-in-out;
    cursor:pointer}

.files-container-item-name button:hover{
    background: #becbd961;
}

button.files-container-item-name-edit-save {
    color: #46c4a1;
}

button.files-container-item-name-edit-cancel {
    color: red;
}
button.files-container-item-delete-temp {
    background: #ff00004a;
    border: 1px solid #ff00004a;
    border-radius: 100%;
    width: 22px;
    height: 22px;
    color: red;
    position: absolute;
    right: 0;
    cursor: pointer;
    transition: background .2s ease-in-out, color .2s ease-in-out;
}

button.files-container-item-delete-temp:hover {
    background: #ff0000;
    color: #ffffffe6;
}
.v-popover{
  position: absolute;
  height:100%;
}
#file-manager-actions-search .searching-icon,
#file-manager-actions-search .clear-search-icon {
    position: absolute;
    right: 10px;
    align-items: center;
    height: calc(100% - 14px);
    margin-top: 7px;
}
#file-manager-actions-search .clear-search-icon{
  cursor: pointer;
}
.usage {display: flex;justify-content: center;padding: 15px;align-items: center;width: 100%;}

.usage > img {width: auto;height: 50px;margin-right: 15px;}

.usage > section {flex-flow: column;flex: 1;text-align: left;}
.get-more-storage {
    padding: 0 15px;
}
.get-more{
  width: 100%;
  background-color: #d07664;
  border-radius: 50px;
  text-transform: capitalize;
  color: #FFF;
  border: unset;
  font-size: 15px;
  height: 40px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 20px;
}
</style>