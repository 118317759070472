const state = {
    open: false
};

const getters = {};
const actions = {
    async toggleOpen({commit, state}){
        commit('toggleOpen',!state.open);
    },
};
const mutations = {
    toggleOpen: (state, data)=>state.open = data
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};